export default {
  Canvas: {
    CanvasWidth: 1400,
    CanvasHeight: 600,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 400,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    // Week
    // { CDATE: '2024-11-22', GROUP1: 1, GROUP2: 3, GROUP3: 7, ACTIVE: 46, },
    // { CDATE: '2024-11-29', GROUP1: 2, GROUP2: 2, GROUP3: 0, ACTIVE: 46, },
    // { CDATE: '2024-12-06', GROUP1: 6, GROUP2: 5, GROUP3: 3, ACTIVE: 43, },
    // { CDATE: '2024-12-13', GROUP1: 16,GROUP2: 2, GROUP3: 0, ACTIVE: 43, },
    // { CDATE: '2024-12-20', GROUP1: 2, GROUP2: 2, GROUP3: 13,ACTIVE: 40, },
    // { CDATE: '2024-12-27', GROUP1: 5, GROUP2: 11,GROUP3: 0, ACTIVE: 40, },
    // { CDATE: '2025-01-03', GROUP1: 6, GROUP2: 11,GROUP3: 3, ACTIVE: 37, },
    // { CDATE: '2025-01-10', GROUP1: 2, GROUP2: 0, GROUP3: 11,ACTIVE: 36, },
    // { CDATE: '2025-01-17', GROUP1: 1, GROUP2: 12,GROUP3: 2, ACTIVE: 34, },
    // { CDATE: '2025-01-24', GROUP1: 6, GROUP2: 4, GROUP3: 2, ACTIVE: 32, },
    // { CDATE: '2025-01-31', GROUP1: 8, GROUP2: 4, GROUP3: 0, ACTIVE: 32, },
    // { CDATE: '2025-02-07', GROUP1: 11,GROUP2: 3, GROUP3: 12,ACTIVE: 30, },
    // { CDATE: '2025-02-14', GROUP1: 1, GROUP2: 1, GROUP3: 6, ACTIVE: 24, },
    // { CDATE: '2025-02-21', GROUP1: 12,GROUP2: 4, GROUP3: 3, ACTIVE: 21, },
    // { CDATE: '2025-02-28', GROUP1: 15,GROUP2: 1, GROUP3: 7, ACTIVE: 14, },
    // { CDATE: '2025-03-07', GROUP1: 17,GROUP2: 1, GROUP3: 1, ACTIVE: 0,  },
    // { CDATE: '2025-03-14', GROUP1: 6, GROUP2: 0, GROUP3: 0, ACTIVE: 13, },
    // { CDATE: '2025-03-21', GROUP1: 2, GROUP2: 0, GROUP3: 0, ACTIVE: 13, },
    // { CDATE: '2025-03-28', GROUP1: 3, GROUP2: 0, GROUP3: 0, ACTIVE: 13, },

    // Month
    { CDATE: '2024-01-22', GROUP1: 1, GROUP2: 3, GROUP3: 7, ACTIVE: 46, },
    { CDATE: '2024-02-29', GROUP1: 2, GROUP2: 2, GROUP3: 0, ACTIVE: 46, },
    { CDATE: '2024-03-06', GROUP1: 6, GROUP2: 5, GROUP3: 3, ACTIVE: 43, },
    { CDATE: '2024-04-13', GROUP1: 16,GROUP2: 2, GROUP3: 0, ACTIVE: 43, },
    { CDATE: '2024-05-20', GROUP1: 2, GROUP2: 2, GROUP3: 13,ACTIVE: 40, },
    { CDATE: '2024-06-27', GROUP1: 5, GROUP2: 11,GROUP3: 0, ACTIVE: 40, },
    { CDATE: '2024-07-03', GROUP1: 6, GROUP2: 11,GROUP3: 3, ACTIVE: 37, },
    { CDATE: '2024-08-10', GROUP1: 2, GROUP2: 0, GROUP3: 11,ACTIVE: 36, },
    { CDATE: '2024-09-17', GROUP1: 1, GROUP2: 12,GROUP3: 2, ACTIVE: 34, },
    { CDATE: '2024-10-24', GROUP1: 6, GROUP2: 4, GROUP3: 2, ACTIVE: 32, },
    { CDATE: '2024-11-31', GROUP1: 8, GROUP2: 4, GROUP3: 0, ACTIVE: 32, },
    { CDATE: '2024-12-07', GROUP1: 11,GROUP2: 3, GROUP3: 12,ACTIVE: 30, },
    { CDATE: '2025-01-14', GROUP1: 1, GROUP2: 1, GROUP3: 6, ACTIVE: 24, },
    { CDATE: '2025-02-21', GROUP1: 12,GROUP2: 4, GROUP3: 3, ACTIVE: 21, },
    { CDATE: '2025-03-28', GROUP1: 15,GROUP2: 1, GROUP3: 7, ACTIVE: 14, },
    { CDATE: '2025-04-07', GROUP1: 17,GROUP2: 1, GROUP3: 1, ACTIVE: 0,  },
    { CDATE: '2025-05-14', GROUP1: 6, GROUP2: 0, GROUP3: 0, ACTIVE: 13, },
    { CDATE: '2025-06-21', GROUP1: 2, GROUP2: 0, GROUP3: 0, ACTIVE: 13, },
    { CDATE: '2025-07-28', GROUP1: 3, GROUP2: 0, GROUP3: 0, ACTIVE: 13, },
  ],


  Queries: {
    SQL1: [
      {CUTOFF: '2025-01-17'}
    ],
  },

  
  JQueries: {
    title: {
      main: {visible: 'Y', x: 50, y: 10, tSize: 15, tColor: '#333333', align: 'left', name: 'TITLE'},
      sub:  {visible: 'Y', x: 95, y: 13, tSize: 10, tColor: '#bcbcbc', align: 'left', name: 'Progress'},
    },

    timeline:    {
      axis:    null, 
      band:    null,
      type:    'month',
      week:    [],
      month:   [], 
      year:    [],
      styles:  {
        week:  {visible: 'Y', tSize: 9,  tColor: '#757575',  lineY: 0,  textY: 7 },  // lineY: 4,  textY: 7 },
        month: {visible: 'Y', tSize: 9, tColor: '#333333',  lineY: 4,  textY: 7},  // lineY: 10, textY: 16},
        year:  {visible: 'Y', tSize: 10, tColor: '#333333',  lineY: 20, textY: 18},  // lineY: 30, textY: 26},
      }
    },

    primary:      {axis: null, max: null, position: 'left',  padding: 25, tSize: 11, tColor: '#333', unit: '' ,   title: 'Issued OBS/ Closed OBS'},
    secondary:   {axis: null, max: null, visible: 'Y', position: 'right', padding: 30, tSize: 11, tColor: '#333', unit: '50%', title: 'No. of Active OBS'     },

    chart: {
      x: 50,
      y: 50,
      width: 600,
      height: 200,
      stackTotal: {visible: 'Y', tSize: 10, tColor: '#44A9DF'},
      lastValue: 12,
      styles: [
        {name: 'Issued', code: 'GROUP1', type: 'BAR',  act : ' ', size: 14, ltype: 0, color: '#44A9DF', vSize: 8, vColor: '#333', node: 0, filter: 'F1', opacity: 1},
        {name: 'Opened', code: 'GROUP2', type: 'BAR',  act : ' ', size: 10, ltype: 0, color: '#83D2F5', vSize: 8, vColor: '#333', node: 0, filter: 'F1', opacity: 1},
        {name: 'Closed', code: 'GROUP3', type: 'BAR',  act : ' ', size: 14, ltype: 0, color: '#B4E3FA', vSize: 8, vColor: '#333', node: 0, filter: 'F1', opacity: 1},
        {name: 'Active', code: 'ACTIVE', type: 'LINE', act : 'Y', size: 1,  ltype: 2, color: '#F35E90', vSize: 9, vColor: '#333', node: 2, filter: '  ', opacity: 1},
      ]
    },

    legends: {
      visible: 'Y',
      y: 38,
      align: 'left',
      tSize: 10, 
      tColor: '#333',
    },

    summary: {
      visible: 'Y',
      x: 500,
      y: 75,
      tSize: 10, 
      tColor: '#333',
      opacity: .5,
      lineHeight: 12,
    },

    filters: {
      F1: '1 = 1',
      F2: '2 = 2',
      F3: '3 = 3',
    }
  }

}