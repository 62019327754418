export default {
  Canvas: {
    CanvasWidth: 1400,
    CanvasHeight: 600,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 400,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    // Week
    { CDATE: '2024-11-22', AREA: 'OVERALL', TOT: 46, ISSUED: 3, CLOSED: 7, ACTIVE: 46, },
    { CDATE: '2024-11-29', AREA: 'OVERALL', TOT: 46, ISSUED: 2, CLOSED: 0, ACTIVE: 46, },
    { CDATE: '2024-12-06', AREA: 'OVERALL', TOT: 46, ISSUED: 5, CLOSED: 3, ACTIVE: 43, },
    { CDATE: '2024-12-13', AREA: 'OVERALL', TOT: 46, ISSUED: 2, CLOSED: 0, ACTIVE: 43, },
    { CDATE: '2024-12-20', AREA: 'OVERALL', TOT: 46, ISSUED: 2, CLOSED: 13, ACTIVE: 40, },
    { CDATE: '2024-12-27', AREA: 'OVERALL', TOT: 46, ISSUED: 11, CLOSED: 0, ACTIVE: 40, },
    { CDATE: '2025-01-03', AREA: 'OVERALL', TOT: 46, ISSUED: 11, CLOSED: 3, ACTIVE: 37, },
    { CDATE: '2025-01-10', AREA: 'OVERALL', TOT: 46, ISSUED: 0, CLOSED: 11, ACTIVE: 36, },
    { CDATE: '2025-01-17', AREA: 'OVERALL', TOT: 46, ISSUED: 12, CLOSED: 2, ACTIVE: 34, },
    { CDATE: '2025-01-24', AREA: 'OVERALL', TOT: 46, ISSUED: 4, CLOSED: 2, ACTIVE: 32, },
    { CDATE: '2025-01-31', AREA: 'OVERALL', TOT: 46, ISSUED: 4, CLOSED: 0, ACTIVE: 32, },
    { CDATE: '2025-02-07', AREA: 'OVERALL', TOT: 46, ISSUED: 3, CLOSED: 12, ACTIVE: 30, },
    { CDATE: '2025-02-14', AREA: 'OVERALL', TOT: 46, ISSUED: 1, CLOSED: 6, ACTIVE: 24, },
    { CDATE: '2025-02-21', AREA: 'OVERALL', TOT: 46, ISSUED: 4, CLOSED: 3, ACTIVE: 21, },
    { CDATE: '2025-02-28', AREA: 'OVERALL', TOT: 46, ISSUED: 1, CLOSED: 7, ACTIVE: 14, },
    { CDATE: '2025-03-07', AREA: 'OVERALL', TOT: 46, ISSUED: 1, CLOSED: 1, ACTIVE: 0, },
    { CDATE: '2025-03-14', AREA: 'OVERALL', TOT: 46, ISSUED: 0, CLOSED: 0, ACTIVE: 13, },
    { CDATE: '2025-03-21', AREA: 'OVERALL', TOT: 46, ISSUED: 0, CLOSED: 0, ACTIVE: 13, },
    { CDATE: '2025-03-28', AREA: 'OVERALL', TOT: 46, ISSUED: 0, CLOSED: 0, ACTIVE: 13, },

    // Month
    // { CDATE: '2024-01-22', AREA: 'OVERALL', TOT: 46, ISSUED: 3, CLOSED: 7, ACTIVE: 46, },
    // { CDATE: '2024-02-29', AREA: 'OVERALL', TOT: 46, ISSUED: 2, CLOSED: 0, ACTIVE: 46, },
    // { CDATE: '2024-03-06', AREA: 'OVERALL', TOT: 46, ISSUED: 5, CLOSED: 3, ACTIVE: 43, },
    // { CDATE: '2024-04-13', AREA: 'OVERALL', TOT: 46, ISSUED: 2, CLOSED: 0, ACTIVE: 43, },
    // { CDATE: '2024-05-20', AREA: 'OVERALL', TOT: 46, ISSUED: 2, CLOSED: 13, ACTIVE: 40, },
    // { CDATE: '2024-06-27', AREA: 'OVERALL', TOT: 46, ISSUED: 11, CLOSED: 0, ACTIVE: 40, },
    // { CDATE: '2024-07-03', AREA: 'OVERALL', TOT: 46, ISSUED: 11, CLOSED: 3, ACTIVE: 37, },
    // { CDATE: '2024-08-10', AREA: 'OVERALL', TOT: 46, ISSUED: 0, CLOSED: 11, ACTIVE: 36, },
    // { CDATE: '2024-09-17', AREA: 'OVERALL', TOT: 46, ISSUED: 12, CLOSED: 2, ACTIVE: 34, },
    // { CDATE: '2024-10-24', AREA: 'OVERALL', TOT: 46, ISSUED: 4, CLOSED: 2, ACTIVE: 32, },
    // { CDATE: '2024-11-31', AREA: 'OVERALL', TOT: 46, ISSUED: 4, CLOSED: 0, ACTIVE: 32, },
    // { CDATE: '2024-12-07', AREA: 'OVERALL', TOT: 46, ISSUED: 3, CLOSED: 12, ACTIVE: 30, },
    // { CDATE: '2025-01-14', AREA: 'OVERALL', TOT: 46, ISSUED: 1, CLOSED: 6, ACTIVE: 24, },
    // { CDATE: '2025-02-21', AREA: 'OVERALL', TOT: 46, ISSUED: 4, CLOSED: 3, ACTIVE: 21, },
    // { CDATE: '2025-03-28', AREA: 'OVERALL', TOT: 46, ISSUED: 1, CLOSED: 7, ACTIVE: 14, },
    // { CDATE: '2025-04-07', AREA: 'OVERALL', TOT: 46, ISSUED: 1, CLOSED: 1, ACTIVE: 0, },
    // { CDATE: '2025-05-14', AREA: 'OVERALL', TOT: 46, ISSUED: 0, CLOSED: 0, ACTIVE: 13, },
    // { CDATE: '2025-06-21', AREA: 'OVERALL', TOT: 46, ISSUED: 0, CLOSED: 0, ACTIVE: 13, },
    // { CDATE: '2025-07-28', AREA: 'OVERALL', TOT: 46, ISSUED: 0, CLOSED: 0, ACTIVE: 13, },
  ],


  Queries: {
    SQL1: [
      {CUTOFF: '2025-01-17'}
    ],
  },

  
  JQueries: {
    title: {
      main: {visible: 'Y', x: 50, y: 10, tSize: 15, tColor: '#333333', align: 'start', name: 'TITLE'},
      sub:  {visible: 'Y', x: 95, y: 13, tSize: 10, tColor: '#bcbcbc', align: 'start', name: 'Progress'},
    },

    timeline:    {
      axis:    null, 
      band:    null,
      type:    'week',
      week:    [],
      month:   [], 
      year:    [],
      styles:  {
        week:  {visible: 'Y', tSize: 9,  tColor: '#757575',  lineY: 4,  textY: 7 },  // lineY: 4,  textY: 7 },
        month: {visible: 'Y', tSize: 10, tColor: '#333333',  lineY: 10, textY: 16},  // lineY: 10, textY: 16},
        year:  {visible: 'Y', tSize: 10, tColor: '#333333',  lineY: 30, textY: 26},  // lineY: 30, textY: 26},
      }
    },

    primary:     {axis: null, max: null, position: 'left',  padding: 25, tSize: 11, tColor: '#333', unit: '' , title: 'Issued OBS/ Closed OBS'},
    secondary:   {axis: null, max: null, position: 'right', padding: 30, tSize: 11, tColor: '#333', unit: '%', title: 'No. of Active OBS'     },

    chart: {
      x: 50,
      y: 50,
      width:    600,
      height:   200,
      barGap:   1,
      lastValue: 12,
      styles: [
        {name: 'Issued', code: 'ISSUED', type: 'BAR',  act : ' ', size: 4, ltype: 0, color: '#FEBED4', vSize: 8, vColor: '#FEBED4', node: 0, opacity: 1},
        {name: 'Closed', code: 'CLOSED', type: 'BAR',  act : 'Y', size: 4, ltype: 0, color: '#9ADBFB', vSize: 8, vColor: '#9ADBFB', node: 0, opacity: 1},
        {name: 'Active', code: 'ACTIVE', type: 'LINE', act : 'Y', size: 1, ltype: 5, color: '#F35E90', vSize: 9, vColor: '#F35E90', node: 2, opacity: 1},
      ]
    },

    legends: {
      visible: 'Y',
      y: 38,
      align: 'left',
      tSize: 10, 
      tColor: '#333',
    },

    summary: {
      visible: 'Y',
      x: 500,
      y: 75,
      tSize: 10, 
      tColor: '#333',
      opacity: .5,
      lineHeight: 12,
    },
  }

}