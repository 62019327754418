export default {
  Canvas: {
    CanvasWidth: 850,
    CanvasHeight: 450,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
  
  DataItems: [
    { GROUP: 'PYRAMID', ITEM: 'FAT',               MONTH: 4,  TOTAL: 7, },
    { GROUP: 'PYRAMID', ITEM: 'LTI',               MONTH: 4,  TOTAL: 7, },
    { GROUP: 'PYRAMID', ITEM: 'RWC',               MONTH: 6,  TOTAL: 7, },
    { GROUP: 'PYRAMID', ITEM: 'MTC',               MONTH: 52, TOTAL: 7, },
    { GROUP: 'PYRAMID', ITEM: 'FAC',               MONTH: 7,  TOTAL: 72,},
    { GROUP: 'BODY',    ITEM: 'Head',              MONTH: 7,  TOTAL: 1, },
    { GROUP: 'BODY',    ITEM: 'Neck',              MONTH: 72, TOTAL: 2, },
    { GROUP: 'BODY',    ITEM: 'Trunk',             MONTH: 7,  TOTAL: 3, },
    { GROUP: 'BODY',    ITEM: 'Upper limbs',       MONTH: 0,  TOTAL: 4, },
    { GROUP: 'BODY',    ITEM: 'Systemic Location', MONTH: 0,  TOTAL: 5, },
    { GROUP: 'BODY',    ITEM: 'Hands',             MONTH: 12, TOTAL: 6,},
    { GROUP: 'BODY',    ITEM: 'Lower limbs',       MONTH: 2,  TOTAL: 7, },
    { GROUP: 'BODY',    ITEM: 'Foot',              MONTH: 3,  TOTAL: 8, },
  ],

  Queries: {
    SQL1: [],
  },

  JQueries: {
    title: 'PERSONAL INJURY',
    tSize: 20,
    scale: .8,
  }
}